<template>
  <div>
    <ul class="nav nav-tabs header-tabs noPrint profile-nav-container">
      <li
        v-for="link in displayedNav"
        :key="link.to || link.action"
        :class="
          link.dropdown
            ? `nav-item dropdown ${link.class || ''}`
            : `nav-item ${link.class || ''}`
        "
      >
        <!-- Dropdown -->
        <a
          class="nav-link dropdown-toggle"
          href="#"
          role="button"
          data-toggle="dropdown"
          v-if="link.dropdown"
        >
          <icon v-if="link.icon" :type="link.icon" class="mr-1" />
          <img
            v-if="link.image"
            :src="link.image.src"
            :width="link.image.width"
            :height="link.image.height"
            class="mr-1"
          />
          {{ link.label }}
        </a>
        <div class="dropdown-menu" v-if="link.dropdown">
          <router-link
            v-for="drop in link.dropdown"
            :key="drop.label"
            :to="getLink(drop.to)"
            class="dropdown-item"
            :class="name == drop.to ? `active` : ``"
          >
            <icon v-if="drop.icon" :type="drop.icon" class="mr-1" />
            <img
              v-if="drop.image"
              :src="drop.image.src"
              :width="drop.image.width"
              :height="drop.image.height"
              class="mr-1"
            />
            {{ drop.label }}
          </router-link>
        </div>

        <!-- Single -->
        <router-link
          v-if="!link.dropdown && !link.action"
          :to="getLink(link.to)"
          class="nav-link"
          :class="
            name == link.to || (link.subLinks && link.subLinks.includes(name))
              ? `active`
              : ``
          "
        >
          <icon v-if="link.icon" :type="link.icon" class="mr-1" />
          <img
            v-if="link.image"
            :src="link.image.src"
            :width="link.image.width"
            :height="link.image.height"
            class="mr-1"
          />
          {{ link.label }}
          <span v-if="link.badgeKey" class="badge badge-pill badge-danger ml-1">
            <span
              v-if="
                link.badgeKey == 'budgets' &&
                !exhaustedBudgetsCountLoading &&
                exhaustedBudgetsCount
              "
              title="Exhausted Budgets Count"
            >
              {{ exhaustedBudgetsCount > 9 ? "9+" : exhaustedBudgetsCount }}
            </span>
          </span>
        </router-link>
        <a
          v-if="!link.dropdown && link.action"
          href="/"
          @click="openCalendarModal"
          class="nav-link calendar-item-link"
        >
          <icon v-if="link.icon" :type="link.icon" class="mr-1" />
          <img
            v-if="link.image"
            :src="link.image.src"
            :width="link.image.width"
            :height="link.image.height"
            class="mr-1"
          />
          {{ link.label }}
        </a>
      </li>
    </ul>
    <sidebar
      :toggle="modalOpened"
      @close="closeCalendarModal"
      :title="`${patientData.name} Events`"
      wrapClass="calendar-tray"
      class="calendar-modal"
    >
      <Calendar
        :key="Math.random()"
        :profilePatientUserId="patientData.user_id"
        @closeModal="modalOpened = false"
        v-if="modalOpened"
      ></Calendar>
    </sidebar>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Calendar from "../../appointment/calendar";
import OkayaIcon from "@/assets/img/okaya-icon.png";

export default {
  name: "PatientNavBar",
  components: {
    Calendar,
  },
  computed: {
    id: function () {
      return this.$route.params.id;
    },
    name: function () {
      return this.$route.name;
    },
    ...mapState({
      user: (state) => state.auth.user,
      patientData: (state) => state.patients.patient,
      exhaustedBudgetsCountLoading: (state) =>
        state.patients.maxhours.exhaustedBudgetsCount.loading,
      exhaustedBudgetsCount: (state) =>
        state.patients.maxhours.exhaustedBudgetsCount.data,
    }),
    displayedNav: function () {
      if (this.user && this.user.isProvider) {
        return this.nav.filter(
          (item) => !item.hideForProvider && !item.onlyPatient
        );
      } else if (this.user && this.user.isPatient) {
        return this.nav.filter((item) => !item.hideForPatient);
      } else {
        return this.nav.filter(
          (item) => !item.onlyPatient && !item.hideForAdmin
        );
      }
    },
  },
  data() {
    return {
      modalOpened: false,
      nav: [
        {
          label: "Profile",
          to: "patients.dashboard",
          icon: "home",
          subLinks: [
            "patients.dashboard",
            "patients.basic",
            "patients.classes",
            "patients.address",
            "patients.phones",
            "patients.emails",
            "patients.services",
            "creditCards",
            "transactions",
          ],
        },
        {
          label: "Healthcare",
          to: "patients.medical",
          icon: "notes-medical",
        },
        {
          label: "Providers",
          to: "patients.providers",
          icon: "user-injured",
        },
        {
          label: "Contacts",
          to: "patients.contacts",
          icon: "file",
        },
        {
          label: "Documents",
          to: "patients.documents",
          icon: "folder-open",
          hideForPatient: false,
          hideForProvider: !this.$can({
            key: "document",
            expectedPermission: "view",
          }),
        },
        {
          label: "Client Timeline",
          to: "patients.timeline",
          icon: "file",
          hideForPatient: true,
        },
        {
          label: "Clinic Forms",
          to: "patients.notes",
          icon: "clipboard",
          hideForPatient: true,
        },
        {
          label: "Intake Forms",
          to: "patients.intakeForms",
          icon: "file-medical",
        },
        {
          label: "Invoices",
          to: "patients.invoices",
          icon: "file-invoice-dollar",
          hideForPatient: true,
          hideForProvider: true,
        },
        {
          label: "Budget",
          to: "patients.budget",
          icon: "file-invoice-dollar",
          hideForPatient: true,
          hideForProvider: true,
          badgeKey: "budgets",
          subLinks: ["patients.budget", "patients.budget-charts"],
        },
        {
          label: "Budget",
          to: "patients.budget.chart",
          icon: "file-invoice-dollar",
          hideForPatient: true,
          hideForAdmin: true,
          subLinks: ["patients.budget", "patients.budget-charts"],
        },
        {
          label: "Shared Recordings",
          to: "patients.shared-recordings",
          icon: "camera",
        },
        {
          label: "Availability",
          to: "patients.availability",
          icon: "user-clock",
        },
        {
          label: "Events",
          action: "openCalendarModal",
          icon: "calendar",
        },
        {
          label: "Integration",
          to: "client.c3.integration",
          icon: "anchor",
          onlyPatient: true,
        },
        {
          label: "Symptoms Diary",
          to: "patients.symptomsDiary",
          icon: "book-medical",
          subLinks: ["patients.symptomsDiary", "patients.symptomsDiaryReports"],
        },
        {
          label: "Symptoms Tracker",
          to: "patients.symptoms",
          icon: "chart-line",
          hideForPatient: true,
          subLinks: ["patients.symptoms", "patients.reports"],
        },
        {
          label: "Okaya",
          to: "patients.okaya.checkIn",
          image: {
            src: OkayaIcon,
            alt: "Okaya",
            width: "",
            height: "20",
          },
          subLinks: ["patients.okaya.checkIn", "patients.okaya.sessions"],
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getExhaustedBudgetsCount: "patients/maxhours/getExhaustedBudgetsCount",
    }),
    getLink(to) {
      return {
        name: to,
        params: {
          id: this.id,
        },
      };
    },
    openCalendarModal(event) {
      event.preventDefault();
      this.modalOpened = true;
    },
    closeCalendarModal() {
      this.modalOpened = false;
    },
  },
  watch: {
    $route(to, from) {
      if (to.name == from.name && !from.query.event && to.query.event) {
        this.$router.replace({ query: "" });
        setTimeout(() => {
          this.modalOpened = true;
        }, 0);
      }
    },
  },
  mounted() {
    if (this.$route.query.event) {
      this.$router.replace({ query: "" });
      setTimeout(() => {
        this.modalOpened = true;
      }, 0);
    }
    if (this.user.isAdmin || this.user.isManagingAdmin) {
      this.getExhaustedBudgetsCount({
        userId: this.patientData.user_id,
      });
    }
  },
};
</script>
